import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ea84c7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text" }
const _hoisted_2 = { class: "headline first-accent" }
const _hoisted_3 = { class: "subline second-accent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_phone_image = _resolveComponent("phone-image")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["screenshot", {
        'type-phone-sm': _ctx.type === 'phone_sm',
        'type-phone-md': _ctx.type === 'phone_md',
        'type-phone-lg': _ctx.type === 'phone_lg',
        'type-tablet-md': _ctx.type === 'tablet_md',
        'type-tablet-lg': _ctx.type === 'tablet_lg'
      }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.headline), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.subline), 1)
        ]),
        _createVNode(_component_phone_image, {
          src: _ctx.src,
          class: _normalizeClass(["image", _ctx.type]),
          type: _ctx.type === 'phone_sm' || _ctx.type === 'phone_md' || _ctx.type === 'phone_lg' ? 'phone' : 'tablet'
        }, null, 8, ["src", "class", "type"])
      ], 2)
    ]),
    _: 1
  }))
}