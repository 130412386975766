
import { defineComponent } from "vue";

import { IonPage } from "@ionic/vue";

import PhoneImage from "../generic/PhoneImage.vue";

export default defineComponent({
  name: "PhoneScreenshotContainer",
  components: {
    IonPage,
    PhoneImage,
  },
  data: () => ({
    src: "",
    headline: "",
    subline: "",
    type: "phone_md",
  }),
  created() {
    if (window.location.hash.length > 1) {
      const data = decodeURIComponent(window.location.hash.substr(1)).split(
        "::"
      );
      this.src = data[0];
      if (data.length > 1) this.headline = data[1];
      if (data.length > 2) this.subline = data[2];
      if (data.length > 3) this.type = data[3];
      console.log("Type", this.type);
    }
  },
});
